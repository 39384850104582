import React from 'react';

const Schedule = () => {
    return (
        <section className="text-center bg-LightBlue py-10">
            <iframe src={"https://sessionize.com/api/v2/za23h4xc/view/GridSmart"} title={"something"} width="100%" height="800px" />
        </section>
    );
};

export default Schedule;
